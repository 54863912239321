@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --bg-nav: #40F99B; /* Medium Spring green */
  --bg-body: #EEE5E9; /* Lavender Blush */
  --bg-task: #51D6FF; /* Vivid Sky Blue */
  --bg-btn: #d64933; /* Cinnabar */
  --bg-modal: #4872fbf3; /* Cerulean Blue */
  --bg-task-dark: #36bde6;
}

body{
    width: 100%;
    min-height: 100vh;
    background: var(--bg-body, white);
    font-family: 'Nunito', sans-serif;
}